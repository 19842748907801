.asset-item {
    cursor: pointer;
}

.asset-item:hover {
    margin: 0px;
    border: 1px solid rgba(0,0,0,0.4);
}

.asset-item.selected {
    margin: 0px;
    border: 1px solid rgba(0,0,0,0.2);
}

.asset-scroll-container {
    height: calc(100vh - 300px);
    overflow: auto;
}

.detais-scroll-container {
    height: calc(100vh - 158px);
    overflow: auto
}
