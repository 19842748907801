.main-container {
    overflow: hidden;
    padding: 16px;
    height: 100%;
}

.case-details-card {
    margin-bottom: 16px;
    position: relative;
}

.case-details-card .MuiCardHeader-root {
    padding-bottom: 0px;
}

.case-details-card .open-in-new-button {
    position: absolute;
    right: 8px;
    top: 8px;
}

.messages-container {
    /* height: calc(100vh - 250px); */
    overflow: auto;
}

.message-from {
    width: 75%;
    margin: 0px 0px 16px 0px;
    margin-bottom: 16px;
}

.message-to {
    width: 75%;
    margin: 0px 0px 16px auto;
}

.message-divider {
    margin: 10px 0px !important;
}

.message-subcontent {
    color: #888888;
    display: flex;
}

.worknotes-container {
    height: calc(100vh - 250px)!important;
    overflow: auto;
    /* padding-bottom: 50px; */
}

.worknote-message {
    background-color: #fff9b3 !important;
}

.text-editor-card {
    margin-top: 16px;
    position: relative;
    border-radius: 5px;
}

.message-text-editor {
    font-family: inherit;
    background-color: #fefefefe !important;
    padding: 16px;
}

.worknote-text-editor {
    font-family: inherit;
    background-color: #fff9b3 !important;
}

.text-editor-action {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.public-DraftEditor-content {
    height: 110px !important;
    overflow: auto;
}

.message-text-editor .public-DraftEditor-content {
    height: 360px !important;
    overflow: auto;
}
