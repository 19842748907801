html {
    font-size: 80%;
}

body {
    margin: 0 0;
    padding: 0 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: rgb(230, 230, 230);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.clickable {
    cursor: pointer;
}

.hidden {
    display: none;
}

.root {
    display: flex;
}

.sidebar {
    width: 148px;
    flex-shrink: 0;
}

.app-main-container {
    height: 100%;
    /* padding: 16px; */
    /* flex-grow: 1; */
    overflow-y: auto;
    overflow-x: auto;
    /* height: calc(100vh - 32px);
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; */
}

.app-main-container::-webkit-scrollbar {
    display: none;
}

.app-container {
    padding: 16px 16px;
}

.panel {
    padding: 16px 16px;
    margin: 0px 0px 16px 0px;
}

.grid-container {
    padding: 16px;
}

a>span>svg {
    margin-right: 5px;
}

button.space,
.space {
    margin: 5px;
}

code {
    font-size: 0.7em;
}

pre {
    white-space: pre-wrap;
    background-color: rgba(100, 100, 100, 0.1);
    padding: 10px;
}

.table-header-button {
    position: relative;
    height: 36px;
    margin-right: 24px !important;
    display: inline-block;
}

.logo {
    width: 60px;
    margin: 10px 0px;
}

.logo-icon {
    width: 20px;
    height: 20px;
    padding: 2px;
}

/* React Leaflet */
.leaflet-container {
    height: 800px;
    width: 100%;
}

.leaflet-control-attribution {
    display: none;
}

.table-image-container-small img {
    max-width: 100%;
    max-height: 30px;
}

.table-image-container img {
    max-width: 100%;
    max-height: 65px;
}


/* form alignment */
.select-align-container {
    margin-top: 16px !important;
}

.switch-align-container {
    margin-top: 16px !important;
}

.button-align {
    margin-top: 16px !important;
}

/* Split pane Resizer */
.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

.Dropzone {
    border: 3px dashed rgba(0, 0, 0, 0.3);
    padding: 8px;
    margin: 5px 0px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    outline: none;
}

.Dropzone:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border: 3px dashed rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.6);
}
